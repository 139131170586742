<template>
  <div class="admin-wrap">
    <div class="admin-info">
      <h1>관리자페이지</h1>
      <p>프로젝트를 업로드하는 관리자페이지입니다.</p>
    </div>
    <div class="admin-work-title">
      <ol id="warning">
        <strong>내용 작성시 주의사항</strong>
        <br />
        <li>1. 디테일페이지에 나타나는 순서대로 입력하시면 됩니다.</li>
        <li>
          2. 이미지를 올릴 땐 자동으로 리사이징되어 올라가기 때문에 늦게 나타날
          수 있습니다.
        </li>
        <li>
          3. 이미지를 업로드 하고 창에 그 이미지가 나타나는걸 확인을 꼭 하시길
          바랍니다. 안나타나면 안올라간거에요!
        </li>
      </ol>
      <!-- <h1>카테고리</h1> -->
      <!-- <input type="radio" name="category" v-model="category" value="film"><label>FILM</label> -->
    </div>
    <div class="admin-work-desc admin-contents">
      <h1>CONTENTS 프로젝트 제목</h1>
      <input type="text" v-model="title" placeholder="제목을 입력하세요..." />
      <h1>클라이언트</h1>
      <input type="text" v-model="client" placeholder="클라이언트" />
      <!-- <h1>프로젝트 내용</h1>
        <label>비디오 링크 : </label><input type="text" v-model="videoURL" placeholder="예시: https://vimeo.com/000000000 "><br> -->
      <h1>이미지 썸네일</h1>
      <p>
        이미지를 추가버튼으로 썸네일로 사용할 이미지를 올리세요. 이미지가
        자동으로 리사이징되어 올라갑니다. 이미지가 나타난다면 제대로 등록 된
        것입니다. <br />
        글자로 나타난다면 이미지로나타나도록 '이미지추가버튼'을 눌러 추가해야
        합니다.
      </p>
      <div class="editor">
        <vue-editor
          class="thumbnail-editor"
          useCustomImageHandler
          @image-added="thumbnailImageAdded"
          v-model="thumbnail"
          placeholder="이미지가 나타나면 썸네일로 등록된 것입니다. 이미지가 나타날 때까지 기다려주세요."
        />
      </div>
      <h1>프로젝트 내용</h1>
      <!-- <input type="text" v-model="category" placeholder="카테고리">
        <textarea placeholder="카테고리 아래 써질 텍스트" v-model="descText"></textarea>
        <textarea placeholder="비디오 링크 아래 써질 텍스트" v-model="descText2"></textarea>
        <input type="text" v-model="descTitle" placeholder="지면이미지 제목"> -->
      <div class="editor">
        <!-- vue editor 사용, 이미지 업로드를 위해 useCustomImageHandler, @image-added="handleImageAdded" 추가함 -->
        <vue-editor
          useCustomImageHandler
          @image-added="handleImageAdded"
          v-model="desc"
          placeholder="영상, 지면이미지, 그 외 텍스트를 여기에 써주세요"
        />
      </div>
      <div class="admin-work-info">
        <h1>CREDIT</h1>
        <label>AGENCY : </label
        ><input type="text" v-model="agency" placeholder="입력하세요.." /><br />
        <label>DIRECTOR : </label
        ><input
          type="text"
          v-model="director"
          placeholder="입력하세요.."
        /><br />
        <label>D.O.P : </label
        ><input type="text" v-model="dop" placeholder="입력하세요.." /><br />
        <label>G.F : </label
        ><input type="text" v-model="gf" placeholder="입력하세요.." /><br />
        <label>ART : </label
        ><input type="text" v-model="art" placeholder="입력하세요.." /><br />
        <label>LOCATION : </label
        ><input
          type="text"
          v-model="location"
          placeholder="입력하세요.."
        /><br />
        <label>MODEL AGENCY : </label
        ><input
          type="text"
          v-model="modelAgency"
          placeholder="입력하세요.."
        /><br />
        <label>STYLE : </label
        ><input type="text" v-model="style" placeholder="입력하세요.." /><br />
        <label>H&amp;M : </label
        ><input type="text" v-model="handm" placeholder="입력하세요.." /><br />
        <label>EDIT : </label
        ><input type="text" v-model="edit" placeholder="입력하세요.." /><br />
        <label>VFX : </label
        ><input type="text" v-model="vfx" placeholder="입력하세요.." /><br />
        <label>COLORGRADING : </label
        ><input
          type="text"
          v-model="colorgranding"
          placeholder="입력하세요.."
        /><br />
        <label>SOUND : </label
        ><input type="text" v-model="sound" placeholder="입력하세요.." /><br />
        <label>AUDIO PD : </label
        ><input
          type="text"
          v-model="audiopd"
          placeholder="입력하세요.."
        /><br />

        <button
          v-if="editTempId === ''"
          @click="saveData($store.state.refContents)"
          :disabled="!validation"
          class="admin-work-btn"
        >
          업로드
        </button>
        <button
          v-if="editTempId !== ''"
          :disabled="!validation"
          class="admin-work-btn"
          @click="editSubmit($store.state.refContents)"
        >
          수정하기
        </button>
      </div>
    </div>
    <!-- <div class="admin-work-desc admin-film" v-else-if="category == 'film' ">
        <h1>FILM 프로젝트 제목</h1>
        <input type="text" v-model="title" placeholder="제목을 입력하세요...">
        <h1>클라이언트</h1>
        <input type="text" v-model="client" placeholder="클라이언트">
        <h1>프로젝트 내용</h1>
        <label>비디오 링크 : </label><input type="text" v-model="videoURL" placeholder="예시: https://vimeo.com/000000000 "><br>
        <h1>이미지 썸네일</h1>
        <p>이미지를 추가버튼으로 썸네일로 사용할 이미지를 올리세요. 이미지가 자동으로 리사이징되어 올라갑니다. 이미지가 나타난다면 제대로 등록 된 것입니다.</p>
        <div class="editor">
            <vue-editor
            class="thumbnail-editor"
            useCustomImageHandler
            @image-added="thumbnailImageAdded"
            v-model="thumbnail" placeholder="이미지가 나타나면 썸네일로 등록된 것입니다. 이미지가 나타날 때까지 기다려주세요."
            />
        </div>
        <div class="admin-work-info">
            <h1>프로젝트 세부 정보</h1>
            <label>AGENCY : </label><input type="text" v-model="angency" placeholder="입력하세요.."><br>
            <label>DIRECTOR : </label><input type="text" v-model="director" placeholder="입력하세요.."><br>
            <label>D.O.P : </label><input type="text" v-model="dop" placeholder="입력하세요.."><br>
            <label>G.F : </label><input type="text" v-model="gf" placeholder="입력하세요.."><br>
            <label>ART : </label><input type="text" v-model="art" placeholder="입력하세요.."><br>
            <label>LOCATION : </label><input type="text" v-model="location" placeholder="입력하세요.."><br>
            <label>MODEL AGENCY : </label><input type="text" v-model="modelAgency" placeholder="입력하세요.."><br>
            <label>STYLE : </label><input type="text" v-model="style" placeholder="입력하세요.."><br>
            <label>H&amp;M : </label><input type="text" v-model="handm" placeholder="입력하세요.."><br>
            <label>EDIT : </label><input type="text" v-model="edit" placeholder="입력하세요.."><br>
            <label>VFX : </label><input type="text" v-model="vfx" placeholder="입력하세요.."><br>
            <label>COLORGRADING : </label><input type="text" v-model="colorgranding" placeholder="입력하세요.."><br>
            <label>SOUND : </label><input type="text" v-model="sound" placeholder="입력하세요.."><br>
            <label>AUDIO PD : </label><input type="text" v-model="audiopd" placeholder="입력하세요.."><br>
            
            <button v-if='editTempId === ""' @click='saveData($store.state.refFilm)' :disabled='!validation' class="admin-work-btn">업로드</button>
            <button v-if='editTempId !== ""' :disabled='!validation' class="admin-work-btn" @click='editSubmit($store.state.refFilm)'>수정하기</button>
        </div>
    </div> -->

    <div class="admin-work-result">
      <h1>CONTENTS 프로젝트 목록</h1>
      <table class="admin-works-list">
        <tr>
          <th>썸네일</th>
          <th>프로젝트 제목</th>
          <!-- <th>내용</th> -->
          <th>클라이언트</th>
          <th>관리</th>
        </tr>
        <tr v-for="(item, i) in dataList" :key="i">
          <!-- <td>{{item.category}}</td> -->
          <td><img :src="item.thumbnail" class="item-desc" /></td>
          <td>{{ item.title }}</td>
          <!-- <td v-html="item.desc"></td> -->
          <td>{{ item.client }}</td>
          <td>
            <button @click="deleteData(item._id, i, $store.state.refContents)">
              삭제
            </button>
            <button @click="editData(item._id, i, $store.state.refContents)">
              수정
            </button>
          </td>
          <!-- <td v-if="category == 'film' ">
                    <button @click='deleteData(item._id, i, $store.state.refFilm)'>삭제</button>
                    <button @click='editData(item._id, i, $store.state.refFilm)'>수정</button>
                </td> -->
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor"; // 뷰 에디터

export default {
  metaInfo() {
    return {
      titleTemplate: "REDHOT | ADMIN",
    };
  },
  components: {
    VueEditor, // Vue.Editor 컴포넌트 추가
  },
  data() {
    return {
      //firebase를 통해 업로드하는 데이터들
      // 업로드한 것 전부
      dataList: [],

      // 프로젝트 이름과 내용
      title: ``,
      // videoURL: ``,
      thumbnail: ``,
      desc: ``,
      // descTitle: ``,
      // descText: ``,
      // descText2: ``,

      // 프로젝트 정보
      // category: ``,
      client: ``,
      agency: ``,
      director: ``,
      dop: ``,
      gf: ``,
      art: ``,
      location: ``,
      modelAgency: ``,
      style: ``,
      handm: ``,
      edit: ``,
      vfx: ``,
      colorgranding: ``,
      sound: ``,
      audiopd: ``,
      thumbnailURL: ``,
      uid: ``,

      editTempId: ``,

      // Image Reszie
      resize: {
        init: function(outputQuality) {
          this.outputQuality =
            outputQuality === "undefined" ? 1 : outputQuality;
        },

        photo: function(standard, file, maxSize, outputType, callback) {
          var _this = this;
          const reader = new FileReader();
          reader.onload = function(readerEvent) {
            if (standard === "h") {
              _this.resizeWidth(
                readerEvent.target.result,
                maxSize,
                outputType,
                callback
              );
            } else if (standard === "w") {
              _this.resizeHeight(
                readerEvent.target.result,
                maxSize,
                outputType,
                callback
              );
            }
          };
          reader.readAsDataURL(file);
        },
        // 가로 폭을 resize함
        resizeWidth: function(dataURL, maxSize, outputType, callback) {
          const _this = this;
          const image = new Image();
          image.onload = function(imageEvent) {
            // 이미지를 onload할 때 resize 함
            const canvas = document.createElement("canvas"); //const가 안되길래 let으로 바꿨음
            let width = image.width;
            let height = image.height;

            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            _this.output(canvas, outputType, callback);
          };
          image.src = dataURL;
        },
        // 세로 높이를 resize함
        resizeHeight: function(dataURL, maxSize, outputType, callback) {
          const _this = this;
          const image = new Image();
          image.onload = function(imageEvent) {
            const canvas = document.createElement("canvas");
            let width = image.width;
            let height = image.height;

            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            _this.output(canvas, outputType, callback);
          };
          image.src = dataURL;
        },
        output: function(canvas, outputType, callback) {
          switch (outputType) {
            case "object":
              canvas.toBlob(
                function(blob) {
                  const obj = {
                    blob: blob,
                    url: canvas.toDataURL("image/png", 1),
                  };
                  callback(obj);
                },
                "image/png",
                1
              );
              break;

            case "file":
              canvas.toBlob(
                function() {
                  callback(blob);
                },
                "image/png",
                1
              );
              break;

            case "dataURL":
              callback(canvas.toDataURL("image/png", 1));
              break;
          }
        },
      },
    };
  },
  // 타이틀과 내용의 길이가 0보다 많아야 업로드가 가능함
  computed: {
    validation() {
      return this.title.length > 0 && this.$store.state.user !== undefined;
    },
    // loadRef(e) {
    //     return this.category
    // }
  },
  watch: {
    // loadRef(e) {
    //     if (this.category == 'film') {
    //         this.loadData(this.$store.state.refFilm)
    //     } else if (this.category == 'contents') {
    //         this.loadData(this.$store.state.refContents)
    //     }
    // }
  },
  // 파일이 실행할때 거의 최상단에서 처음으로 시작함
  created() {
    this.loadData(this.$store.state.refContentsOrderd);
  },
  // methods는 함수를 정의할 때 사용
  methods: {
    thumbnailImageAdded(file, Editor, cursorLocation, resetUploader) {
      //Vue editor 에서 제공하는 이미지핸들러
      const ex = file.name.split(".")[file.name.split(".").length - 1]; // split으로 .을 기준으로 두번째 배열인 것을 가져옴, 파일이름은 제외하고 뒤의 확장자만 가져온다
      const fileName = `thumbnail${new Date().valueOf()}.${ex}`; // 확장자를 가져오고 그 앞에 초단위의 날짜를 입력하여 이름이 중복되지 않게 한다
      const imageRef = this.$store.state.storageRef.child(fileName); // 위 이미지이름으로 변환하고 그걸 업로드한다
      const self = this;

      // resize하여 그걸 r.blob으로 가져옴
      this.resize.photo("w", file, 720, "object", function(r) {
        console.log(r);
        const blob = r.blob;

        imageRef.put(blob).then(function(snapshot) {
          console.log("이미지 업로드 완료");
          snapshot.ref
            .getDownloadURL()
            .then(function(url) {
              Editor.insertEmbed(cursorLocation, "image", url); //업로드한 이미지를 에디터 안(커서로케이션)에 나타나게 한다
              resetUploader();
              self.thumbnailURL = url;
              console.log(self.thumbnailURL); //이미지를 업로드 후에 다운로드 가능한 url이 나타나게 한다
            })
            .catch((err) => {
              // 에러코드
              console.log(err);
            });
        });
      });
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      //Vue editor 에서 제공하는 이미지핸들러
      const ex = file.name.split(".")[file.name.split(".").length - 1]; // split으로 .을 기준으로 두번째 배열인 것을 가져옴, 파일이름은 제외하고 뒤의 확장자만 가져온다
      const fileName = `image${new Date().valueOf()}.${ex}`; // 확장자를 가져오고 그 앞에 초단위의 날짜를 입력하여 이름이 중복되지 않게 한다
      const imageRef = this.$store.state.storageRef.child(fileName); // 위 이미지이름으로 변환하고 그걸 업로드한다
      const self = this;

      // resize하여 그걸 r.blob으로 가져옴
      this.resize.photo("w", file, 1920, "object", function(r) {
        console.log(r);
        const blob = r.blob;

        imageRef.put(blob).then(function(snapshot) {
          console.log("이미지 업로드 완료");
          snapshot.ref
            .getDownloadURL()
            .then(function(url) {
              console.log(url); //이미지를 업로드 후에 다운로드 가능한 url이 나타나게 한다
              Editor.insertEmbed(cursorLocation, "image", url); //업로드한 이미지를 에디터 안(커서로케이션)에 나타나게 한다
              resetUploader();
            })
            .catch((err) => {
              // 에러코드
              console.log(err);
            });
        });
      });
    },
    saveData(refIndex) {
      const self = this;
      refIndex
        .add({
          title: this.title,
          thumbnail: this.thumbnailURL,
          desc: this.desc,
          // videoURL: this.videoURL,
          // category: this.category,
          // descTitle: this.descTitle,
          // descText: this.descText,
          // descText2: this.descText2,

          client: this.client,
          agency: this.agency,
          director: this.director,
          dop: this.dop,
          gf: this.gf,
          art: this.art,
          location: this.location,
          modelAgency: this.modelAgency,
          style: this.style,
          handm: this.handm,
          edit: this.edit,
          vfx: this.vfx,
          colorgranding: this.colorgranding,
          sound: this.sound,
          audiopd: this.audiopd,
          uid: new Date().valueOf(),

          createdAt: new Date(), // 언제 올리는지
        })
        .then(function(docRef) {
          console.log("Document written with ID: ", docRef.id);
          self.loadData(refIndex);
          alert("업로드가 완료됐습니다.");
          // 내가 쓴 것에 빈 문자를 넣어 모두 지움
          self.title = ``;
          self.thumbnail = ``;
          self.desc = ``;
          // self.videoURL =``
          // self.category = ``
          // self.descTitle = ``
          // self.descText = ``
          // self.descText2 = ``

          self.client = ``;
          self.agency = ``;
          self.director = ``;
          self.dop = ``;
          self.gf = ``;
          self.art = ``;
          self.location = ``;
          self.modelAgency = ``;
          self.style = ``;
          self.handm = ``;
          self.edit = ``;
          self.vfx = ``;
          self.colorgranding = ``;
          self.sound = ``;
          self.audiopd = ``;
          self.uid = new Date.valueOf();
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    },
    editSubmit(refIndex) {
      const self = this; // this를 못쓰는 곳에 self로 쓰기 위함

      refIndex
        .doc(this.editTempId)
        .update({
          //this를 붙인 이유는 현재 'db'가 정의되지 않았기 때문.
          title: this.title,
          // thumbnail : this.thumbnailURL,
          desc: this.desc,
          // videoURL: this.videoURL,
          // category: this.category,
          // descTitle: this.descTitle,
          // descText: this.descText,
          // descText2: this.descText2,

          client: this.client,
          agency: this.agency,
          director: this.director,
          dop: this.dop,
          gf: this.gf,
          art: this.art,
          location: this.location,
          modelAgency: this.modelAgency,
          style: this.style,
          handm: this.handm,
          edit: this.edit,
          vfx: this.vfx,
          colorgranding: this.colorgranding,
          sound: this.sound,
          audiopd: this.audiopd,
        })
        .then(function(docRef) {
          self.loadData(refIndex);
          alert("수정이 완료됐습니다.");
          self.title = ``;
          // self.thumbnail = ``
          self.desc = ``;
          // self.videoURL = ``
          // self.category = ``
          // self.descTitle = ``
          // self.descText = ``
          // self.descText2 = ``

          self.client = ``;
          self.agency = ``;
          self.director = ``;
          self.dop = ``;
          self.gf = ``;
          self.art = ``;
          self.location = ``;
          self.modelAgency = ``;
          self.style = ``;
          self.handm = ``;
          self.edit = ``;
          self.vfx = ``;
          self.colorgranding = ``;
          self.sound = ``;
          self.audiopd = ``;
          self.editTempId = "";
        })
        .catch(function(error) {
          alert("수정에 실패하였습니다.");
          console.log(error);
        });
    },
    loadData(refIndex) {
      refIndex.get().then((querySnapshot) => {
        const result = [];
        querySnapshot.forEach((doc) => {
          const temp = doc.data();
          temp._id = doc.id;
          result.push(temp);
        });
        this.dataList = result.sort(function(a, b) {
          // 내림차순
          return a.createdAt - b.createdAt;
        });
      });
    },
    deleteData(_id, index, refIndex) {
      const self = this;
      if (confirm("정말 삭제할까요?")) {
        refIndex
          .doc(_id)
          .delete()
          .then(function() {
            self.dataList.splice(index, 1);
            alert("삭제가 완료되었습니다.");
          });
      } else {
        alert("삭제가 취소되었습니다.");
      }
    },
    editData(_id, index, refIndex) {
      const self = this;

      this.title = this.dataList[index].title;
      // this.category = this.dataList[index].category
      // this.thumbnail = this.dataList[index].thumbnail
      this.desc = this.dataList[index].desc;
      // this.videoURL = this.dataList[index].videoURL
      this.client = this.dataList[index].client;
      // this.descTitle = this.dataList[index].descTitle
      // this.descText = this.dataList[index].descText
      // this.descText2 = this.dataList[index].descText2
      this.agency = this.dataList[index].agency;
      this.director = this.dataList[index].director;
      this.dop = this.dataList[index].dop;
      this.gf = this.dataList[index].gf;
      this.art = this.dataList[index].art;
      this.location = this.dataList[index].location;
      this.modelAgency = this.dataList[index].modelAgency;
      this.style = this.dataList[index].style;
      this.handm = this.dataList[index].handm;
      this.edit = this.dataList[index].edit;
      this.vfx = this.dataList[index].vfx;
      this.colorgranding = this.dataList[index].colorgranding;
      this.sound = this.dataList[index].sound;
      this.audiopd = this.dataList[index].audiopd;

      this.editTempId = _id;
    },
  },
};
</script>

<style>
.admin-wrap {
  margin: 10%;
}
.admin-wrap div h1 {
  margin: 20px 0;
  color: #e60121;
  font-weight: 700;
  font-size: 1.3rem;
}
/* 페이지 설명 */
.admin-wrap .admin-info {
  margin: 0 0 24px 0;
}
.admin-wrap .admin-work-title {
  margin-bottom: 50px;
}
#warning {
  line-height: 24pt;
  margin: 0 0 72px;
}
#warning strong {
  font-weight: 700;
  font-size: 1.2rem;
  color: #e60121;
}
.admin-wrap .admin-work-title label {
  margin: 0 24px 0 8px;
}
.admin-wrap .admin-work-title input[type="radio"]:checked + label {
  color: #e60121;
}
.admin-wrap .admin-work-title input[type="text"] {
  padding: 10px;
  width: 70%;
  border: 0;
  border-bottom: 2px solid #e60121;
  font-size: 15pt;
}
/* 프로젝트 정보 */
.admin-wrap .admin-work-info input[type="date"],
.admin-wrap .admin-work-info input[type="text"],
.admin-wrap .admin-work-info input[type="number"] {
  border: 0;
  border-bottom: 2px solid #e60121;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 12pt;
}
.admin-wrap .admin-work-info {
  margin-bottom: 50px;
}
.admin-wrap .admin-work-desc {
  margin-bottom: 50px;
}
.admin-work-desc p {
  margin: 24px 0 32px;
}
/* 비디오 링크 */
.admin-work-desc input[type="text"] {
  width: 42vw;
  border: 0;
  border-bottom: 2px solid #e60121;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 12pt;
}
/* 썸네일 */
.thumbnail img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}
/* 에디터 */
.editor {
  width: 100%;
  height: 420px;
}
.editor /deep/ h1 {
  font-size: 1.7rem;
  line-height: 2.5rem;
  color: #000 !important;
}
.editor /deep/ h2 {
  font-size: 1.5rem;
  line-height: 2.3rem;
}
.editor /deep/ h3 {
  font-size: 1.3rem;
  line-height: 2rem;
}
.editor /deep/ h4 {
  font-size: 1.2rem;
  line-height: 1.7rem;
}
.editor /deep/ h5 {
  font-size: 1.1rem;
  line-height: 1.5rem;
}
.editor /deep/ strong {
  font-weight: 700;
}
.editor /deep/ em {
  font-style: oblique;
}
.editor /deep/ p {
  font-size: 0.8rem;
  line-height: 1.4rem;
}
.editor /deep/ iframe {
  margin: 32px auto;
  width: 100%;
  height: 40vh;
}
.admin-wrap .admin-work-desc .editor .quillWrapper {
  width: 80%;
  height: 320px;
  margin-bottom: 60px;
  font-size: 12pt;
}
.admin-wrap .admin-work-desc textarea {
  display: block;
  width: 80%;
  height: 300px;
  margin: 1vh 0;
  padding: 1%;
  font-size: 1.2rem;
  resize: none;
}
.item-desc {
  width: 100%;
  height: 50px;
  overflow: hidden;
}
.admin-work-btn {
  margin: 42px 0 120px;
  appearance: none;
  padding: 10px 20px;
  color: #e60121;
  font-weight: 700;
  font-size: 1.1rem;
  border: 2px solid #e60121;
}
.admin-work-btn:hover {
  transition: 0.7s;
  background: #e60121;
  color: white;
  cursor: pointer;
}
.admin-work-result {
  width: 75vw;
}
.admin-work-result .admin-works-list {
  width: 75vw;
  display: block;
  border-collapse: collapse;
}
.admin-work-result .admin-works-list th {
  width: 25vw;
  padding: 20px 0;
  border-top: 2px solid #e60121;
  border-bottom: 2px solid #e60121;
}
.admin-work-result .admin-works-list td {
  text-align: center;
  padding: 10px 0;
}
.admin-work-result .admin-works-list td:first-child,
.admin-work-result .admin-works-list td:last-child {
  width: 8%;
}
.admin-work-result .admin-works-list td:nth-child(2) {
  width: 28%;
}
.admin-work-result .admin-works-list td:nth-child(3) {
  width: 36%;
}
.admin-work-result .admin-works-list td:nth-child(4) {
  width: 20%;
}
.admin-work-result .admin-works-list tr:nth-child(even) {
  background: #efefef;
}
.admin-work-result .admin-works-list tr:last-child {
  border-bottom: 2px solid #e60121;
  width: 10%;
}
.admin-work-result .admin-works-list td img {
  max-width: 100px;
}
.admin-work-result .admin-works-list td {
  height: 2vh;
  overflow: scroll;
}
.admin-work-result .admin-works-list iframe {
  max-width: 100px;
  height: 56.25px;
}
.admin-work-result .admin-works-list button {
  width: 30%;
  margin: 0 8px;
  padding: 8px;
  font-size: 1.1rem;
}
</style>
